import {
  LoginApi,
  OrgApi,
  UserApi,
  OrgCourseApi,
  UserProgressApi,
  StudyApi,
  StudyMonthlyCountApi,
  StudyLogApi,
  LicenseApi,
} from '@/openapi/user/';

import ApiUtility from '@/classes/common/ApiUtility';




export default class UserApiUtility extends ApiUtility {

  /** APIの基本パス（継承先でこれを変更すれば他のAPIにアクセスできる想定） */
  protected static readonly apiBasePath = '/psapi/user/v1';

  /** 認証/認可トークンキー */
  protected static readonly tokenKey = 'pusertoken';

  /** クッキートークンのパス（空文字以外なら設定する） */
  protected static readonly cookieTokenPath: string = '/psapi/user';

  /** トークン保存をローカルストレージにするか否か */
  protected static readonly isPersistentToken: boolean = true;




  /**
   * openapi-generatorで生成したログインAPI通信用クラスを取得します
   *
   * @returns
   */
  public static getLoginApi(): LoginApi {
    return new LoginApi(undefined, this.apiBasePath, this.getInstance(false));
  }

  /**
   * openapi-generatorで生成した組織APIクラスを取得します
   *
   * @returns
   */
  public static getOrgApi(): OrgApi {
    return new OrgApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成したユーザーAPIクラスを取得します
   *
   * @returns
   */
  public static getUserApi(): UserApi {
    return new UserApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成した組織コースAPIクラスを取得します
   *
   * @returns
   */
  public static getOrgCourseApi(): OrgCourseApi {
    return new OrgCourseApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成した学習者進度APIクラスを取得します
   *
   * @returns
   */
  public static getUserProgressApi(): UserProgressApi {
    return new UserProgressApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成した学習APIクラスを取得します
   *
   * @returns
   */
  public static getStudyApi(): StudyApi {
    return new StudyApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成した学習月カウントAPIクラスを取得します
   *
   * @returns
   */
  public static getStudyMonthlyCountApi(): StudyMonthlyCountApi {
    return new StudyMonthlyCountApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成した学習ログAPIクラスを取得します
   *
   * @returns
   */
  public static getStudyLogApi(): StudyLogApi {
    return new StudyLogApi(undefined, this.apiBasePath, this.getInstance());
  }

  /**
   * openapi-generatorで生成したライセンスAPIクラスを取得します
   *
   * @returns
   */
  public static getLicenseApi(): LicenseApi {
    return new LicenseApi(undefined, this.apiBasePath, this.getInstance());
  }



}
