import mixins from 'vue-typed-mixins';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';
import AlertDialog from '@/user/components/dialog/alert/AlertDialog.vue';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';
import UserApiUtility from '@/classes/common/UserApiUtility';
import { HttpStatusCode } from '@/openapi/enum';
import { LicenseRequest } from '@/openapi/user';



/** 入力欄数 */
const INPUT_KEY_BOX_COUNT = 4;

/** 入力欄あたりの最大入力文字数 */
const MAX_INPUT_KEY_PER_BOX = 4;

export default mixins(OrgRoomMixin, RoutesMixin, UserMixin).extend({

  name: 'Account',

  components: {
    Header,
    BackButton,
    LogoutButton,
    AlertDialog
  },

  data(): {
    inputKeys: string[];
    visibleSuccessDialog: boolean;
    visibleErrorDialog: boolean;
  } {
    return {
      inputKeys: new Array(INPUT_KEY_BOX_COUNT).fill(''),
      visibleSuccessDialog: false,
      visibleErrorDialog: false
    };
  },

  async created(): Promise<void> {
    this.loadUserState(true);
  },

  methods: {
    back(): void {
      this.$router.push(this.routes.CourseSelect);
    },
    onInput(old: string, index: number): void {
      // 規定文字数以上、または半角英数以外の入力の場合は入力を戻してreturn
      if (this.inputKeys[index].length > MAX_INPUT_KEY_PER_BOX || (/[^A-Za-z0-9]/).test(this.inputKeys[index])) {
        this.inputKeys[index] = old;
        return;
      }

      // 規定文字に達した、かつ入力欄が最後以外の場合は次の入力欄にフォーカスを移す
      if (this.inputKeys[index].length === MAX_INPUT_KEY_PER_BOX && index < INPUT_KEY_BOX_COUNT - 1) {
        const elms = this.$refs.inputLicense as HTMLElement[];
        elms[index + 1].focus();
      }
    },
    async submit(): Promise<void> {
      // ライセンス更新
      const param: LicenseRequest = { licenseKey: this.inputKeys.join('') };
      const response = await UserApiUtility.getLicenseApi().updateLicense(param);
      if (response.status === HttpStatusCode.badRequest) this.visibleErrorDialog = true;
      else                                               this.visibleSuccessDialog = true;
    },
    async reloadUserState(): Promise<void> {
      await this.loadUserState(true);
      this.visibleSuccessDialog = false;
    }
  },



});
