import Vue from 'vue';

export default Vue.extend({

  name: 'WindowAdjustMixin',

  watch: {
    $_windowSize: {
      handler() {
        this.onChangeWindowSizeHandler();
      },
      // immediate: true,  これ有効にするとタグが生成される前にonChangeWindowSizeHandlerが実行されて困ることがある
      deep: true,
    }
  },

  computed: {
    isPortrait(): boolean {
      return Vue.prototype.$_windowSize.height >= Vue.prototype.$_windowSize.width;
    },
  },

  methods: {
    calculateRatio(num: number): number {
      return num * Vue.prototype.$_windowSize.ratio;
    },
    /**
     * 画面サイズ変更検知時のハンドリング
     */
    onChangeWindowSizeHandler(): void {
      // 呼び出し側で処理を実装
    }
  }

});