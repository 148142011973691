import { render, staticRenderFns } from "./AuthorizedHistory.vue?vue&type=template&id=0c022b7a&scoped=true&"
import script from "./AuthorizedHistory.ts?vue&type=script&lang=ts&"
export * from "./AuthorizedHistory.ts?vue&type=script&lang=ts&"
import style0 from "./AuthorizedHistory.css?vue&type=style&index=0&id=0c022b7a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c022b7a",
  null
  
)

export default component.exports