import Vue from 'vue';



export default Vue.extend({

  name: 'BackButton',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    back(): void {
      this.$emit('onClick');
    }
  }



});
