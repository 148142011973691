import mixins from 'vue-typed-mixins';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';

import { CourseListItemResponse } from '@/openapi/user/index';
import { CourseId } from '@/openapi/enum';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';
import StudyMixin from '@/user/mixins/StudyMixin';

import { USER_STORAGE_KEY } from '@/classes/common/Constants';
import UserApiUtility from '@/classes/common/UserApiUtility';




export default mixins(OrgRoomMixin, RoutesMixin, UserMixin, StudyMixin).extend({

  name: 'CourseSelect',

  components: {
    Header,
    BackButton,
    LogoutButton,
  },

  data(): {
    isLoaded: boolean;
    courses: CourseListItemResponse[]
  } {
    return {
      isLoaded: false,
      courses: []
    };
  },

  filters: {
    courseIcon(courseId: CourseId): string {
      switch (courseId) {
        case CourseId.clean:
          return 'broom';
        case CourseId.cooking:
          return 'utensils';
        case CourseId.transfer:
          return 'train';
        case CourseId.janken:
          return 'hand-peace';
        case CourseId.coloring:
          return 'paint-brush';
        default:
          return '';
      }
    }
  },

  async created(): Promise<void> {
    this.clearProgress();
    await this.loadUserState(true);
    const response = await UserApiUtility.getOrgCourseApi().listOrgCourse(this.orgId);
    this.courses = response.data;
    this.isLoaded = true;
  },

  methods: {
    selectCourse(course: CourseListItemResponse): void {
      // SessionStrageからページを削除
      sessionStorage.removeItem(USER_STORAGE_KEY.UNIT_PAGE);

      // コンテンツタイプが0の場合は学習開始画面、1の場合は学習内容選択画面へ
      const route  = course.contentType === 0 ? this.routes.OnewayStart : this.routes.SelectionUnitSelect;
      const params = { courseId: course.courseId, courseName: course.courseName };
      this.$router.push({name: route.name, params: params});
    },
    toAccountSetting(): void {
      this.$router.push(this.routes.Account);
    }
  }

});
