import Vue from 'vue';
import moment from 'moment';

import { ResultNo } from '@/openapi/enum';

export default Vue.extend({

  name: 'StudyLogMixin',


  filters: {
    studyStartDt(date: number, time: number, format = 'YYYY/MM/DD HH:mm:ss'): string {
      if (date === undefined || date === null || time === undefined || time === null) return '';
      const dtString = date.toString() + ('000000' + time).slice(-6);
      return moment(dtString, 'YYYYMMDDHHmmss').format(format);
    }
  },

  methods: {
    displayResult(resultNo: ResultNo): string {
      switch (resultNo) {
        case ResultNo.yet:     return '未';
        case ResultNo.success: return '<i class="fi-record"></i>';
        case ResultNo.fail:    return '<i class="fi-x"></i>';
      }
    },
  },

});
