import mixins from 'vue-typed-mixins';
import _ from 'lodash';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import ConfirmDialog from '@/user/components/dialog/confirm/ConfirmDialog.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';


export default mixins(RoutesMixin).extend({

  name: 'Study',

  components: {
    Header,
    BackButton,
    ConfirmDialog,
    Cooking: () => import(/* webpackPrefetch: true */ '@/user/pages/authorized/study/cooking/Cooking.vue'),
    Transfer: () => import(/* webpackPrefetch: true */ '@/user/pages/authorized/study/transfer/Transfer.vue'),
    Clean: () => import(/* webpackPrefetch: true */ '@/user/pages/authorized/study/clean/Clean.vue'),
    Janken: () => import(/* webpackPrefetch: true */ '@/user/pages/authorized/study/janken/Janken.vue'),
    Coloring: () => import(/* webpackPrefetch: true */ '@/user/pages/authorized/study/coloring/Coloring.vue'),
  },

  data(): {
    visibleBackDialog: boolean;
  } {
    return {
      visibleBackDialog: false,
    };
  },

  computed: {
    title(): string {
      const courseName = this.$route.params.courseName;
      const sectionNo  = this.$route.query.sectionNo;
      const unitNo     = this.$route.query.unitNo;
      return `${courseName}　Lv.${sectionNo}-${unitNo}`;
    },
    courseComponent(): string {
      return _.capitalize(this.$route.params.courseId);
    }
  },

  methods: {
    back(): void {
      this.visibleBackDialog = false;
      this.$router.push(this.routes.SelectionUnitSelect);
    },
  }

});
