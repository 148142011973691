import mixins from 'vue-typed-mixins';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';



export default mixins(RoutesMixin).extend({

  name: 'Exception',

  components: {
    Header,
    BackButton,
    LogoutButton,
  },

});
