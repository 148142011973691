import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=3808db50&scoped=true&"
import script from "./Account.ts?vue&type=script&lang=ts&"
export * from "./Account.ts?vue&type=script&lang=ts&"
import style0 from "./Account.css?vue&type=style&index=0&id=3808db50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3808db50",
  null
  
)

export default component.exports