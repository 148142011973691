import mixins from 'vue-typed-mixins';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';



export default mixins(OrgRoomMixin, RoutesMixin, UserMixin).extend({

  name: 'AuthorizedHistory',

  created(): void {
    this.loadUserState();
  },

});
