import mixins from 'vue-typed-mixins';
import { AxiosResponse } from 'axios';
import { Dialog } from 'element-ui';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';
import GoogleSignin from '@/components/googleSignin/GoogleSignin.vue';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import UserApiUtility from '@/classes/common/UserApiUtility';
import { GoogleLoginRequest, LoginRequest } from '@/openapi/user';
import { HttpStatusCode } from '@/openapi/enum';
import { ErrorResponse } from '@/openapi/common';
import { PUBLIC_PATH } from '@/classes/common/Constants';




export default mixins(OrgRoomMixin, RoutesMixin, UserMixin, WindowAdjustMixin).extend({

  name: 'Login',

  components: {
    Header,
    BackButton,
    LogoutButton,
    elDialog: Dialog,
    GoogleSignin,
  },

  data(): {
    logoImgSrc: string;
    password: string;
    visibleLoginErrorDialog: boolean;
    errorResponse: ErrorResponse | null;
  } {
    return {
      logoImgSrc: '',
      password: '',
      visibleLoginErrorDialog: false,
      errorResponse: null,
    };
  },

  async created(): Promise<void> {
    await this.setOrgRoomState(
      this.$route.params.orgId,
      this.$route.params.roomId,
    );
    // デモ教室の場合、体験ユーザーとして処理
    if (this.isDemoRoom) {
      this.guestLogin();
      return;
    }

    // tokenが存在する場合（ログアウトしていない場合）、科目選択画面に遷移
    if (UserApiUtility.hasToken()) this.$router.push(this.routes.CourseSelect);
  },

  methods: {
    async login(): Promise<void> {
      this.errorResponse = null;

      const params = {
        orgId: this.orgId,
        roomId: this.roomId,
        userPass: this.password
      } as LoginRequest;

      const response: AxiosResponse<void | ErrorResponse> = await UserApiUtility.getLoginApi().login(params);
      if (response.status === HttpStatusCode.unauthorized) {
        this.errorResponse = response.data as ErrorResponse;
        this.visibleLoginErrorDialog = true;
        return;
      }

      this.$router.push(this.routes.CourseSelect);
    },
    guestLogin(): void {
      this.setGuestUserState();
      this.$router.push(this.routes.CourseSelect);
    },
    inputPassword(num: number): void {
      if (20 < this.password.length + 1) return;
      this.password += num;
    },
    slicePasswordTail(): void {
      if (this.password.length === 0) return;
      this.password = this.password.slice(0, -1);
    },
    async onGoogleAuthorised(googleUser: any): Promise<void> { // eslint-disable-line @typescript-eslint/no-explicit-any
      const params: GoogleLoginRequest = {
        roomId: this.roomId,
        credential: googleUser.credential
      };

      const response: AxiosResponse<void | ErrorResponse> = await UserApiUtility.getLoginApi().googleLogin(params);
      if (response.status === HttpStatusCode.unauthorized) {
        this.errorResponse = { message: '認証に失敗しました。' } as ErrorResponse;
        this.visibleLoginErrorDialog = true;
        return;
      }

      this.$router.push(this.routes.CourseSelect);
    },
  },

  watch: {
    // OrgRoomMixinのstateを監視し、ロゴ画像を更新する
    resourceName: {
      handler(): void {
        if (this.resourceName.length > 0) {
          this.logoImgSrc = `${PUBLIC_PATH}resource/user/img/logo_${this.resourceName}.svg`;
        }
      },
      immediate: true
    }
  },


});
