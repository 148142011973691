import mixins from 'vue-typed-mixins';

import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';


export default mixins(WindowAdjustMixin).extend({

  name: 'GoogleSignin',

  props: {
    width: {
      type: Number,
      required: true
    }
  },

  data(): {
    googleAuth: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  } {
    return {
      googleAuth: null
    };
  },

  mounted(): void {
    this.googleAuth = (window as any).google; // eslint-disable-line @typescript-eslint/no-explicit-any
    this.googleAuth.accounts.id.initialize({
      client_id: process.env.VUE_APP_API_GOOGLE_CLIENT_ID,
      callback: (googleUser: any) => this.$emit('onAuthorised', googleUser) // eslint-disable-line @typescript-eslint/no-explicit-any
    });
    this.$_renderGoogleSigninButton();
  },

  methods: {
    // WindowAdjustMixin上書き（学習側のみ）
    onChangeWindowSizeHandler(): void {
      this.$_renderGoogleSigninButton();
    },
    $_renderGoogleSigninButton(): void {
      const elem = this.$refs.googleSignIn as HTMLElement;
      if (elem === undefined) return;

      elem.innerHTML = ''; // 表示を一度空にする
      this.googleAuth.accounts.id.renderButton(
        this.$refs.googleSignIn,
        { width: this.width }
      );
    },
  }

});
