import Vue from 'vue';
import _ from 'lodash';

import { RoutesType, PublicRoutes, AuthorizedRoutes, AuthorizedHistoryRoutes } from '@/user/router/AppRouteConfig';

export default Vue.extend({

  name: 'RoutesMixin',

  data(): {
    /** ルート情報 */
    routes: RoutesType,
  } {
    return {
      routes: _.merge({}, PublicRoutes, AuthorizedRoutes, AuthorizedHistoryRoutes)
    };
  }

});