import mixins from 'vue-typed-mixins';

import OrgRoomMixin from '@/user/mixins/OrgRoomMixin';
import UserMixin from '@/user/mixins/UserMixin';
import StudyMixin from '@/user/mixins/StudyMixin';

import { PublicRoutes } from '@/user/router/AppRouteConfig';



export default mixins(OrgRoomMixin, UserMixin, StudyMixin).extend({

  name: 'LogoutButton',

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data(): {
    visibleLogoutDialog: boolean
  } {
    return {
      visibleLogoutDialog: false
    };
  },

  computed: {
    logoutConfirmText(): string {
      if (this.isManabi) return 'ウィンドウを閉じます。よろしいですか？';
      return 'ログアウトしてもよろしいですか？';
    },
  },

  methods: {
    logoutUser(): void {
      this.clearProgress();
      this.logout();
      this.visibleLogoutDialog = false;
      // まなびポケットの場合はウインドウを閉じる
      if (this.isManabi) window.close();
      else               this.$router.push(PublicRoutes.LoginRedirect);
    },
  }



});
