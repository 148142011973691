import Vue from 'vue';
import mixins from 'vue-typed-mixins';
import moment, { Moment } from 'moment';
import _ from 'lodash';

import { StudyRequest, CourseProgress } from '@/openapi/user';
import { CourseId, ResultNo } from '@/openapi/enum';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';
import UserApiUtility from '@/classes/common/UserApiUtility';


export enum STUDY_TYPE {
  NORMAL, // 学習
  REVIEW, // 復習（成功済みユニット再学習）
}

export enum STUDY_RESULT {
  SCORE_RETRY,     // 採点で不正解
  UNIT_SUCCESS,    // ユニット正解
  COURSE_COMPLETE, // ユニット正解してコース内全ユニット正解
};

/** 科目別セクション/ユニット数定義 */
interface SectionUnitCount {
  courseId: CourseId,
  sectionCount: number,
  unitCountPerSection: number
}

/** 科目別セクション/ユニット数 */
const SECTION_UNIT_COUNT: SectionUnitCount[] = [
  {
    courseId: CourseId.clean,
    sectionCount: 10,
    unitCountPerSection: 10
  },
  {
    courseId: CourseId.cooking,
    sectionCount: 10,
    unitCountPerSection: 10
  },
  {
    courseId: CourseId.transfer,
    sectionCount: 10,
    unitCountPerSection: 10
  },
  {
    courseId: CourseId.janken,
    sectionCount: 5,
    unitCountPerSection: 10
  },
  {
    courseId: CourseId.coloring,
    sectionCount: 10,
    unitCountPerSection: 10
  }
];

type State = {
  studyStartMoment: Moment;
  studyRequest: StudyRequest;
  restTimeInterval: number | null;
  scoreResult: number | null;  // 結果モーダルを閉じた後の処理分岐用
  studyResult: number | null;
  studyResultTimeout: number | null;
  progresses: CourseProgress[];
};

const state = Vue.observable<State>({
  studyStartMoment: moment(),
  studyRequest: {
    courseId: CourseId.transfer,
    sectionNo: 0,
    unitNo: 0,
    stageName: '',
    resultNo: ResultNo.fail,
    studyMemo: '',
    studyStartDt: moment().format(),
    studySec: 0,
  },
  restTimeInterval: null,
  scoreResult: null,
  studyResult: null,
  studyResultTimeout: null,
  progresses: [],
});




export default mixins(RoutesMixin, UserMixin).extend({

  name: 'StudyMixin',

  data(): {
    studyCourseId: CourseId;
    sectionNo: number;
    unitNo: number;
  } {
    return {
      studyCourseId: this.$route.params.courseId as CourseId,
      sectionNo: Number(this.$route.query.sectionNo),
      unitNo: Number(this.$route.query.unitNo),
    };
  },

  computed: {
    studyType(): number {
      const type = this.$route.query.studyType;
      if (type === undefined) return STUDY_TYPE.NORMAL;
      return Number(type);
    },
    isTutorial(): boolean {
      return this.sectionNo === 0;
    },
    stageName(): string {
      return state.studyRequest.stageName;
    },
    studyResult(): number | null {
      return state.studyResult;
    },
    scoreResult(): number | null {
      return state.scoreResult;
    },
    progresses(): CourseProgress[] {
      return state.progresses;
    },
    sectionCountSet(): SectionUnitCount | undefined {
      return SECTION_UNIT_COUNT.find(suc => suc.courseId === this.$route.params.courseId);
    },
    sectionCount(): number {
      return this.sectionCountSet !== undefined ? this.sectionCountSet.sectionCount : 0;
    },
    unitCountPerSection(): number {
      return this.sectionCountSet !== undefined ? this.sectionCountSet.unitCountPerSection : 0;
    },
    isCourseComplete(): boolean {
      for (let i = 0; i < this.sectionCount; i++) {
        for (let j = 0; j < this.unitCountPerSection; j++) {
          if (!this.checkSuccessUnit(i + 1, j + 1)) return false;
        }
      }
      return true;
    }
  },

  methods: {
    initializeStudyVariable(): void {
      this.clearRestTimeInterval();
      state.scoreResult = null;
      state.studyResult = null;
      state.studyResultTimeout = null;

      this.studyCourseId = this.$route.params.courseId as CourseId; // 初期化不要だが、一律で初期化しておく
      this.sectionNo     = Number(this.$route.query.sectionNo);
      this.unitNo        = Number(this.$route.query.unitNo);
    },

    initializeStudyRequest(): void {
      // 学習結果初期化
      state.studyStartMoment = moment();
      state.studyRequest = {
        courseId:     this.studyCourseId,
        sectionNo:    this.sectionNo,
        unitNo:       this.unitNo,
        stageName:    '',
        resultNo:     ResultNo.success,
        studyMemo:    '',
        studyStartDt: state.studyStartMoment.format(),
        studySec:     0,
      };
    },

    async loadProgressState(courseId: CourseId): Promise<void> {
      // ゲストの場合は何もせず終了
      if (this.isGuest) return;

      const response = await UserApiUtility.getUserProgressApi().showSelection(courseId);
      state.progresses = response.data;
    },

    clearProgress(): void {
      state.progresses = [];
    },

    checkSuccessUnit(sectionNo: number, unitNo: number): boolean {
      const section = this.progresses.find(p => p.sectionNo === sectionNo);
      return section !== undefined && section.unitNos.some(no => no === unitNo);
    },

    startTimer(): void {
      // 学習開始日時を記録
      state.studyStartMoment = moment();

      const checkRestTime = () => {
        // 学習が終了している場合はタイマーストップ
        if (state.studyResult !== null && state.studyResult >= STUDY_RESULT.UNIT_SUCCESS) {
          this.clearRestTimeInterval();
        }
      };
      if (state.restTimeInterval === null) state.restTimeInterval = setInterval(checkRestTime, 100);
    },

    clearRestTimeInterval(): void {
      if (state.restTimeInterval === null) return;

      clearInterval(state.restTimeInterval);
      state.restTimeInterval = null;
    },

    judgeScoreResult(isCorrect: boolean): void {
      if (isCorrect) {
        this.$_saveStudyCorrectResult();
      } else {
        this.$_setScoreResult(STUDY_RESULT.SCORE_RETRY);
        this.$_sendStudyResult(false); // ここで失敗ログの送信をしておく
      }
    },

    clearStudyResult(): void {
      state.studyResult = null;
      if (state.studyResultTimeout !== null) {
        clearTimeout(state.studyResultTimeout);
        state.studyResultTimeout = null;
      }
    },

    // section/unit 終了時に呼ぶ想定
    setStudyResult(studyResult: STUDY_RESULT): void {
      state.studyResult = studyResult;
    },

    clickResultModalImage(): void {
      this.$router.push({
        name:   this.routes.SelectionUnitSelect.name,
        params: this.$route.params
      });
      this.clearStudyResult();
    },

    $_setScoreResult(scoreResult: STUDY_RESULT): void {
      state.scoreResult = scoreResult;
      state.studyResult = scoreResult;
      state.studyResultTimeout = setTimeout(() => this.clearStudyResult(), 1000);
    },

    $_saveStudyCorrectResult(): void {
      let studyResult = STUDY_RESULT.UNIT_SUCCESS;
      switch (this.studyType) {
        case STUDY_TYPE.NORMAL:
          // 全ユニット終了チェックと体験ユーザ用に進捗の更新
          this.$_updateProgress();
          // 全ユニット終了した場合
          if (this.isCourseComplete) studyResult = STUDY_RESULT.COURSE_COMPLETE;
          break;
        case STUDY_TYPE.REVIEW:
          // なにもしない
          break;
      }

      this.setStudyResult(studyResult);
      this.$_sendStudyResult(true);
    },

    async $_sendStudyResult(isCorrect: boolean): Promise<void> {
      if (this.isGuest) return; // 体験モードの場合は送信しない。
      state.studyRequest.resultNo = isCorrect ? ResultNo.success : ResultNo.fail;
      state.studyRequest.studySec = Math.round((moment().valueOf() - state.studyStartMoment.valueOf()) / 1000);
      state.studyRequest.studyStartDt = state.studyStartMoment.format();
      await UserApiUtility.getStudyApi().study(state.studyRequest);
    },

    $_updateProgress(): void {
      const progress = state.progresses.find(p => p.sectionNo === this.sectionNo);
      // 進捗にセクションがない場合はセクションを追加
      if (progress === undefined) {
        state.progresses.push({
          sectionNo: this.sectionNo,
          unitNos: [this.unitNo]
        });
        state.progresses = _.sortBy(state.progresses, p => p.sectionNo);

      // 進捗にセクションがある場合はユニットNoを追加
      } else {
        // 追加済みの場合はreturn
        if (progress.unitNos.includes(this.unitNo)) return;

        progress.unitNos.push(this.unitNo);
        progress.unitNos = _.sortBy(progress.unitNos);
      }
    },

  }

});
