/* tslint:disable */
/* eslint-disable */
/**
 * プログラミング先生 enum定義
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import ApiUtility from '@/classes/common/ApiUtility';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * コンテンツタイプ(0：一本道、1：選択式)
 * @export
 * @enum {string}
 */
export enum ContentType {
    oneWay = 0,
    selection = 1
}

/**
 * コースID
 * @export
 * @enum {string}
 */
export enum CourseId {
    transfer = 'transfer',
    cooking = 'cooking',
    clean = 'clean',
    janken = 'janken',
    coloring = 'coloring'
}

/**
 * コース進捗モード（0：チュートリアル、1：診断、2：診断（再挑戦）、3：学習、4：学習（再挑戦）、5：判定、6：判定（再挑戦）、7：完了）  計算：全て使用  読解：tutorial, check, lesson, complete のみ使用  すうじ：lesson, lessonRetry, complete のみ使用 
 * @export
 * @enum {string}
 */
export enum CourseProgressMode {
    tutorial = 0,
    check = 1,
    checkRetry = 2,
    lesson = 3,
    lessonRetry = 4,
    judge = 5,
    judgeRetry = 6,
    complete = 7
}

/**
 * HTTPステータスコード
 * @export
 * @enum {string}
 */
export enum HttpStatusCode {
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    requestTimeout = 408,
    internalServerError = 500
}

/**
 * 無効フラグ(0：有効、1：無効)
 * @export
 * @enum {string}
 */
export enum InvalidFlag {
    off = 0,
    on = 1
}

/**
 * 結果No(0：未、1：〇、2：✖)
 * @export
 * @enum {string}
 */
export enum ResultNo {
    yet = 0,
    success = 1,
    fail = 2
}

/**
 * ユーザー種別(0：通常、1：体験、2：こんぷ、3：コンプまえ、4：正解　※「1：体験」はDBに登録しないユーザー)
 * @export
 * @enum {string}
 */
export enum UserType {
    normal = 0,
    guest = 1,
    complete = 2,
    oneUnitLeft = 3,
    correct = 4
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * dummy path
         * @summary dummy path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyGet: async (options: any = {}): Promise<RequestArgs> => {
            let localVarPath = `/dummy`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/dummy');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * dummy path
         * @summary dummy path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async dummyGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await DefaultApiAxiosParamCreator(configuration).dummyGet(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * dummy path
         * @summary dummy path
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        dummyGet(options?: any): AxiosPromise<void> {
            return DefaultApiFp(configuration).dummyGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * dummy path
     * @summary dummy path
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public dummyGet(options?: any) {
        return DefaultApiFp(this.configuration).dummyGet(options).then((request) => request(this.axios, this.basePath));
    }
}


