import { render, staticRenderFns } from "./Unauthorized.vue?vue&type=template&id=0e3117fc&scoped=true&"
import script from "./Unauthorized.ts?vue&type=script&lang=ts&"
export * from "./Unauthorized.ts?vue&type=script&lang=ts&"
import style0 from "./Unauthorized.css?vue&type=style&index=0&id=0e3117fc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e3117fc",
  null
  
)

export default component.exports