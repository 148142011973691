/* tslint:disable */
/* eslint-disable */
/**
 * プログラミング先生 学習者API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import ApiUtility from '@/classes/common/ApiUtility';
import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * コンテンツタイプ(0：一本道、1：選択式)
 * @export
 * @enum {string}
 */
export enum ContentType {
    oneWay = 0,
    selection = 1
}

/**
 * コースID
 * @export
 * @enum {string}
 */
export enum CourseId {
    transfer = 'transfer',
    cooking = 'cooking',
    clean = 'clean',
    janken = 'janken',
    coloring = 'coloring'
}

/**
 * コース
 * @export
 * @interface CourseListItemResponse
 */
export interface CourseListItemResponse {
    /**
     * 
     * @type {CourseId}
     * @memberof CourseListItemResponse
     */
    courseId: CourseId;
    /**
     * コース名
     * @type {string}
     * @memberof CourseListItemResponse
     */
    courseName: string;
    /**
     * 
     * @type {ContentType}
     * @memberof CourseListItemResponse
     */
    contentType: ContentType;
}
/**
 * 
 * @export
 * @interface CourseProgress
 */
export interface CourseProgress {
    /**
     * 単元No
     * @type {number}
     * @memberof CourseProgress
     */
    sectionNo: number;
    /**
     * 正答ユニットNo（1本道は学習中ユニットNoが1要素のみ含まれる配列となる想定）
     * @type {Array<number>}
     * @memberof CourseProgress
     */
    unitNos: Array<number>;
    /**
     * 
     * @type {CourseProgressOption}
     * @memberof CourseProgress
     */
    option?: CourseProgressOption;
}
/**
 * コース進捗モード（0：チュートリアル、1：診断、2：診断（再挑戦）、3：学習、4：学習（再挑戦）、5：判定、6：判定（再挑戦）、7：完了）  計算：全て使用  読解：tutorial, check, lesson, complete のみ使用  すうじ：lesson, lessonRetry, complete のみ使用 
 * @export
 * @enum {string}
 */
export enum CourseProgressMode {
    tutorial = 0,
    check = 1,
    checkRetry = 2,
    lesson = 3,
    lessonRetry = 4,
    judge = 5,
    judgeRetry = 6,
    complete = 7
}

/**
 * 
 * @export
 * @interface CourseProgressOption
 */
export interface CourseProgressOption {
    /**
     * 
     * @type {CourseProgressMode}
     * @memberof CourseProgressOption
     */
    mode?: CourseProgressMode;
}
/**
 * エラーレスポンス
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {HttpStatusCode}
     * @memberof ErrorResponse
     */
    status: HttpStatusCode;
    /**
     * APIパス
     * @type {string}
     * @memberof ErrorResponse
     */
    path: string;
    /**
     * 発生日時
     * @type {string}
     * @memberof ErrorResponse
     */
    timestamp: string;
    /**
     * エラー概要
     * @type {string}
     * @memberof ErrorResponse
     */
    error: string;
    /**
     * エラー概要
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
    /**
     * エラー詳細
     * @type {string}
     * @memberof ErrorResponse
     */
    trace?: string;
    /**
     * 例外クラス
     * @type {string}
     * @memberof ErrorResponse
     */
    exception?: string;
    /**
     * 入力エラー内容(status=400 の場合のみ)
     * @type {Array<FieldErrorResponse>}
     * @memberof ErrorResponse
     */
    errors?: Array<FieldErrorResponse>;
}
/**
 * 入力フィールドエラー
 * @export
 * @interface FieldErrorResponse
 */
export interface FieldErrorResponse {
    /**
     * 入力フィールド
     * @type {string}
     * @memberof FieldErrorResponse
     */
    field: string;
    /**
     * 入力オブジェクト
     * @type {string}
     * @memberof FieldErrorResponse
     */
    objectName: string;
    /**
     * エラー内容
     * @type {string}
     * @memberof FieldErrorResponse
     */
    defaultMessage: string;
}
/**
 * 
 * @export
 * @interface GoogleLoginRequest
 */
export interface GoogleLoginRequest {
    /**
     * 教室ID
     * @type {string}
     * @memberof GoogleLoginRequest
     */
    roomId: string;
    /**
     * Google認証IDトークン
     * @type {string}
     * @memberof GoogleLoginRequest
     */
    credential: string;
}
/**
 * HTTPステータスコード
 * @export
 * @enum {string}
 */
export enum HttpStatusCode {
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    requestTimeout = 408,
    internalServerError = 500
}

/**
 * 
 * @export
 * @interface LicenseRequest
 */
export interface LicenseRequest {
    /**
     * ライセンスキー
     * @type {string}
     * @memberof LicenseRequest
     */
    licenseKey: string;
}
/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 組織ID
     * @type {string}
     * @memberof LoginRequest
     */
    orgId: string;
    /**
     * 教室ID
     * @type {string}
     * @memberof LoginRequest
     */
    roomId: string;
    /**
     * 学習者パスワード
     * @type {string}
     * @memberof LoginRequest
     */
    userPass: string;
}
/**
 * 
 * @export
 * @interface OrgResponse
 */
export interface OrgResponse {
    /**
     * 組織ID
     * @type {string}
     * @memberof OrgResponse
     */
    orgId: string;
    /**
     * 組織名
     * @type {string}
     * @memberof OrgResponse
     */
    orgName?: string;
    /**
     * リソース名
     * @type {string}
     * @memberof OrgResponse
     */
    resourceName: string;
}
/**
 * 結果No(0：未、1：〇、2：✖)
 * @export
 * @enum {string}
 */
export enum ResultNo {
    yet = 0,
    success = 1,
    fail = 2
}

/**
 * 
 * @export
 * @interface StudyLogListItemResponse
 */
export interface StudyLogListItemResponse {
    /**
     * 学習ログPK
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    pk: number;
    /**
     * ニックネーム
     * @type {string}
     * @memberof StudyLogListItemResponse
     */
    nickname?: string;
    /**
     * コース名
     * @type {string}
     * @memberof StudyLogListItemResponse
     */
    courseName?: string;
    /**
     * 単元NO
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    sectionNo?: number;
    /**
     * ユニットNO
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    unitNo?: number;
    /**
     * ステージ名
     * @type {string}
     * @memberof StudyLogListItemResponse
     */
    stageName?: string;
    /**
     * 
     * @type {ResultNo}
     * @memberof StudyLogListItemResponse
     */
    resultNo: ResultNo;
    /**
     * 学習メモ
     * @type {string}
     * @memberof StudyLogListItemResponse
     */
    studyMemo?: string;
    /**
     * 学習秒
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    studySec?: number;
    /**
     * 学習開始日付
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    studyStartDate?: number;
    /**
     * 学習開始時刻
     * @type {number}
     * @memberof StudyLogListItemResponse
     */
    studyStartTime?: number;
}
/**
 * 
 * @export
 * @interface StudyRequest
 */
export interface StudyRequest {
    /**
     * 
     * @type {CourseId}
     * @memberof StudyRequest
     */
    courseId: CourseId;
    /**
     * 単元No
     * @type {number}
     * @memberof StudyRequest
     */
    sectionNo: number;
    /**
     * ユニットNo
     * @type {number}
     * @memberof StudyRequest
     */
    unitNo: number;
    /**
     * ステージ名
     * @type {string}
     * @memberof StudyRequest
     */
    stageName: string;
    /**
     * 
     * @type {ResultNo}
     * @memberof StudyRequest
     */
    resultNo: ResultNo;
    /**
     * 学習メモ
     * @type {string}
     * @memberof StudyRequest
     */
    studyMemo: string;
    /**
     * 学習開始日時
     * @type {string}
     * @memberof StudyRequest
     */
    studyStartDt: string;
    /**
     * 学習時間
     * @type {number}
     * @memberof StudyRequest
     */
    studySec: number;
}
/**
 * 
 * @export
 * @interface UserResponse
 */
export interface UserResponse {
    /**
     * ニックネーム
     * @type {string}
     * @memberof UserResponse
     */
    nickname?: string;
    /**
     * 学習者メモ
     * @type {string}
     * @memberof UserResponse
     */
    userMemo?: string;
    /**
     * 
     * @type {UserType}
     * @memberof UserResponse
     */
    userType: UserType;
    /**
     * 学習者パスワード
     * @type {string}
     * @memberof UserResponse
     */
    userPass: string;
    /**
     * 有効期限
     * @type {number}
     * @memberof UserResponse
     */
    expirationDate?: number;
}
/**
 * ユーザー種別(0：通常、1：体験、2：こんぷ、3：コンプまえ、4：正解　※「1：体験」はDBに登録しないユーザー)
 * @export
 * @enum {string}
 */
export enum UserType {
    normal = 0,
    guest = 1,
    complete = 2,
    oneUnitLeft = 3,
    correct = 4
}


/**
 * LicenseApi - axios parameter creator
 * @export
 */
export const LicenseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ライセンスを利用済状態にして、学習者の有効期限を延長更新します
         * @summary ライセンス更新
         * @param {LicenseRequest} licenseRequest ライセンス情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense: async (licenseRequest: LicenseRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'licenseRequest' is not null or undefined
            if (licenseRequest === null || licenseRequest === undefined) {
                throw new RequiredError('licenseRequest','Required parameter licenseRequest was null or undefined when calling updateLicense.');
            }
            let localVarPath = `/license`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('PUT', '/license');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof licenseRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(licenseRequest !== undefined ? licenseRequest : {})
                : (licenseRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LicenseApi - functional programming interface
 * @export
 */
export const LicenseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * ライセンスを利用済状態にして、学習者の有効期限を延長更新します
         * @summary ライセンス更新
         * @param {LicenseRequest} licenseRequest ライセンス情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLicense(licenseRequest: LicenseRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LicenseApiAxiosParamCreator(configuration).updateLicense(licenseRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LicenseApi - factory interface
 * @export
 */
export const LicenseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * ライセンスを利用済状態にして、学習者の有効期限を延長更新します
         * @summary ライセンス更新
         * @param {LicenseRequest} licenseRequest ライセンス情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLicense(licenseRequest: LicenseRequest, options?: any): AxiosPromise<void> {
            return LicenseApiFp(configuration).updateLicense(licenseRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LicenseApi - object-oriented interface
 * @export
 * @class LicenseApi
 * @extends {BaseAPI}
 */
export class LicenseApi extends BaseAPI {
    /**
     * ライセンスを利用済状態にして、学習者の有効期限を延長更新します
     * @summary ライセンス更新
     * @param {LicenseRequest} licenseRequest ライセンス情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LicenseApi
     */
    public updateLicense(licenseRequest: LicenseRequest, options?: any) {
        return LicenseApiFp(this.configuration).updateLicense(licenseRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LoginApi - axios parameter creator
 * @export
 */
export const LoginApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary Googleログイン
         * @param {GoogleLoginRequest} googleLoginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin: async (googleLoginRequest: GoogleLoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'googleLoginRequest' is not null or undefined
            if (googleLoginRequest === null || googleLoginRequest === undefined) {
                throw new RequiredError('googleLoginRequest','Required parameter googleLoginRequest was null or undefined when calling googleLogin.');
            }
            let localVarPath = `/login/google`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('POST', '/login/google');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof googleLoginRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(googleLoginRequest !== undefined ? googleLoginRequest : {})
                : (googleLoginRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary ログイン
         * @param {LoginRequest} loginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (loginRequest: LoginRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginRequest' is not null or undefined
            if (loginRequest === null || loginRequest === undefined) {
                throw new RequiredError('loginRequest','Required parameter loginRequest was null or undefined when calling login.');
            }
            let localVarPath = `/login`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('POST', '/login');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof loginRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(loginRequest !== undefined ? loginRequest : {})
                : (loginRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoginApi - functional programming interface
 * @export
 */
export const LoginApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary Googleログイン
         * @param {GoogleLoginRequest} googleLoginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async googleLogin(googleLoginRequest: GoogleLoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).googleLogin(googleLoginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary ログイン
         * @param {LoginRequest} loginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(loginRequest: LoginRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await LoginApiAxiosParamCreator(configuration).login(loginRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * LoginApi - factory interface
 * @export
 */
export const LoginApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary Googleログイン
         * @param {GoogleLoginRequest} googleLoginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        googleLogin(googleLoginRequest: GoogleLoginRequest, options?: any): AxiosPromise<void> {
            return LoginApiFp(configuration).googleLogin(googleLoginRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
         * @summary ログイン
         * @param {LoginRequest} loginRequest 認証情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(loginRequest: LoginRequest, options?: any): AxiosPromise<void> {
            return LoginApiFp(configuration).login(loginRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LoginApi - object-oriented interface
 * @export
 * @class LoginApi
 * @extends {BaseAPI}
 */
export class LoginApi extends BaseAPI {
    /**
     * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
     * @summary Googleログイン
     * @param {GoogleLoginRequest} googleLoginRequest 認証情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public googleLogin(googleLoginRequest: GoogleLoginRequest, options?: any) {
        return LoginApiFp(this.configuration).googleLogin(googleLoginRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証成功時はレスポンスヘッダーに認証/認可トークンを返却します（※体験ユーザーは使用しない）
     * @summary ログイン
     * @param {LoginRequest} loginRequest 認証情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoginApi
     */
    public login(loginRequest: LoginRequest, options?: any) {
        return LoginApiFp(this.configuration).login(loginRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgApi - axios parameter creator
 * @export
 */
export const OrgApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 組織IDに紐づく組織を取得します
         * @summary 組織
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOrg: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling showOrg.');
            }
            let localVarPath = `/org/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/org/{orgId}');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgApi - functional programming interface
 * @export
 */
export const OrgApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 組織IDに紐づく組織を取得します
         * @summary 組織
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOrg(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrgResponse>> {
            const localVarAxiosArgs = await OrgApiAxiosParamCreator(configuration).showOrg(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrgApi - factory interface
 * @export
 */
export const OrgApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 組織IDに紐づく組織を取得します
         * @summary 組織
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOrg(orgId: string, options?: any): AxiosPromise<OrgResponse> {
            return OrgApiFp(configuration).showOrg(orgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgApi - object-oriented interface
 * @export
 * @class OrgApi
 * @extends {BaseAPI}
 */
export class OrgApi extends BaseAPI {
    /**
     * 組織IDに紐づく組織を取得します
     * @summary 組織
     * @param {string} orgId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgApi
     */
    public showOrg(orgId: string, options?: any) {
        return OrgApiFp(this.configuration).showOrg(orgId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgCourseApi - axios parameter creator
 * @export
 */
export const OrgCourseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 組織IDで絞り込んだ組織コース一覧を並び順の昇順で取得します
         * @summary 組織コース一覧
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgCourse: async (orgId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling listOrgCourse.');
            }
            let localVarPath = `/orgCourse/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/orgCourse/{orgId}');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgCourseApi - functional programming interface
 * @export
 */
export const OrgCourseApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 組織IDで絞り込んだ組織コース一覧を並び順の昇順で取得します
         * @summary 組織コース一覧
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listOrgCourse(orgId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseListItemResponse>>> {
            const localVarAxiosArgs = await OrgCourseApiAxiosParamCreator(configuration).listOrgCourse(orgId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * OrgCourseApi - factory interface
 * @export
 */
export const OrgCourseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 組織IDで絞り込んだ組織コース一覧を並び順の昇順で取得します
         * @summary 組織コース一覧
         * @param {string} orgId 組織ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgCourse(orgId: string, options?: any): AxiosPromise<Array<CourseListItemResponse>> {
            return OrgCourseApiFp(configuration).listOrgCourse(orgId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgCourseApi - object-oriented interface
 * @export
 * @class OrgCourseApi
 * @extends {BaseAPI}
 */
export class OrgCourseApi extends BaseAPI {
    /**
     * 組織IDで絞り込んだ組織コース一覧を並び順の昇順で取得します
     * @summary 組織コース一覧
     * @param {string} orgId 組織ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgCourseApi
     */
    public listOrgCourse(orgId: string, options?: any) {
        return OrgCourseApiFp(this.configuration).listOrgCourse(orgId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudyApi - axios parameter creator
 * @export
 */
export const StudyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 学習結果を登録します
         * @summary 学習結果登録
         * @param {StudyRequest} studyRequest 学習結果情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        study: async (studyRequest: StudyRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'studyRequest' is not null or undefined
            if (studyRequest === null || studyRequest === undefined) {
                throw new RequiredError('studyRequest','Required parameter studyRequest was null or undefined when calling study.');
            }
            let localVarPath = `/study`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('POST', '/study');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            const nonString = typeof studyRequest !== 'string';
            const needsSerialization = nonString && configuration && configuration.isJsonMime
                ? configuration.isJsonMime(localVarRequestOptions.headers['Content-Type'])
                : nonString;
            localVarRequestOptions.data =  needsSerialization
                ? JSON.stringify(studyRequest !== undefined ? studyRequest : {})
                : (studyRequest || "");

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyApi - functional programming interface
 * @export
 */
export const StudyApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 学習結果を登録します
         * @summary 学習結果登録
         * @param {StudyRequest} studyRequest 学習結果情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async study(studyRequest: StudyRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await StudyApiAxiosParamCreator(configuration).study(studyRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudyApi - factory interface
 * @export
 */
export const StudyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 学習結果を登録します
         * @summary 学習結果登録
         * @param {StudyRequest} studyRequest 学習結果情報
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        study(studyRequest: StudyRequest, options?: any): AxiosPromise<void> {
            return StudyApiFp(configuration).study(studyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudyApi - object-oriented interface
 * @export
 * @class StudyApi
 * @extends {BaseAPI}
 */
export class StudyApi extends BaseAPI {
    /**
     * 学習結果を登録します
     * @summary 学習結果登録
     * @param {StudyRequest} studyRequest 学習結果情報
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyApi
     */
    public study(studyRequest: StudyRequest, options?: any) {
        return StudyApiFp(this.configuration).study(studyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudyLogApi - axios parameter creator
 * @export
 */
export const StudyLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークン、コースID、学習年月で絞り込んだ学習ログ一覧を学習開始日付、学習開始時刻の降順で取得します
         * @summary 学習ログ一覧
         * @param {CourseId} courseId コースID
         * @param {number} studyMonth 学習年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudyLog: async (courseId: CourseId, studyMonth: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling listStudyLog.');
            }
            // verify required parameter 'studyMonth' is not null or undefined
            if (studyMonth === null || studyMonth === undefined) {
                throw new RequiredError('studyMonth','Required parameter studyMonth was null or undefined when calling listStudyLog.');
            }
            let localVarPath = `/studyLog/{courseId}/{studyMonth}`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)))
                .replace(`{${"studyMonth"}}`, encodeURIComponent(String(studyMonth)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/studyLog/{courseId}/{studyMonth}');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyLogApi - functional programming interface
 * @export
 */
export const StudyLogApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークン、コースID、学習年月で絞り込んだ学習ログ一覧を学習開始日付、学習開始時刻の降順で取得します
         * @summary 学習ログ一覧
         * @param {CourseId} courseId コースID
         * @param {number} studyMonth 学習年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudyLog(courseId: CourseId, studyMonth: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<StudyLogListItemResponse>>> {
            const localVarAxiosArgs = await StudyLogApiAxiosParamCreator(configuration).listStudyLog(courseId, studyMonth, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudyLogApi - factory interface
 * @export
 */
export const StudyLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 認証/認可トークン、コースID、学習年月で絞り込んだ学習ログ一覧を学習開始日付、学習開始時刻の降順で取得します
         * @summary 学習ログ一覧
         * @param {CourseId} courseId コースID
         * @param {number} studyMonth 学習年月
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudyLog(courseId: CourseId, studyMonth: number, options?: any): AxiosPromise<Array<StudyLogListItemResponse>> {
            return StudyLogApiFp(configuration).listStudyLog(courseId, studyMonth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudyLogApi - object-oriented interface
 * @export
 * @class StudyLogApi
 * @extends {BaseAPI}
 */
export class StudyLogApi extends BaseAPI {
    /**
     * 認証/認可トークン、コースID、学習年月で絞り込んだ学習ログ一覧を学習開始日付、学習開始時刻の降順で取得します
     * @summary 学習ログ一覧
     * @param {CourseId} courseId コースID
     * @param {number} studyMonth 学習年月
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyLogApi
     */
    public listStudyLog(courseId: CourseId, studyMonth: number, options?: any) {
        return StudyLogApiFp(this.configuration).listStudyLog(courseId, studyMonth, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StudyMonthlyCountApi - axios parameter creator
 * @export
 */
export const StudyMonthlyCountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンとコースIDで絞り込んだ学習月カウント.学習年月をグループ化して降順で取得します
         * @summary 学習年月一覧
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudyMonth: async (courseId: CourseId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling listStudyMonth.');
            }
            let localVarPath = `/studyMonthlyCount/{courseId}/month`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/studyMonthlyCount/{courseId}/month');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudyMonthlyCountApi - functional programming interface
 * @export
 */
export const StudyMonthlyCountApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンとコースIDで絞り込んだ学習月カウント.学習年月をグループ化して降順で取得します
         * @summary 学習年月一覧
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listStudyMonth(courseId: CourseId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<number>>> {
            const localVarAxiosArgs = await StudyMonthlyCountApiAxiosParamCreator(configuration).listStudyMonth(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * StudyMonthlyCountApi - factory interface
 * @export
 */
export const StudyMonthlyCountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 認証/認可トークンとコースIDで絞り込んだ学習月カウント.学習年月をグループ化して降順で取得します
         * @summary 学習年月一覧
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listStudyMonth(courseId: CourseId, options?: any): AxiosPromise<Array<number>> {
            return StudyMonthlyCountApiFp(configuration).listStudyMonth(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudyMonthlyCountApi - object-oriented interface
 * @export
 * @class StudyMonthlyCountApi
 * @extends {BaseAPI}
 */
export class StudyMonthlyCountApi extends BaseAPI {
    /**
     * 認証/認可トークンとコースIDで絞り込んだ学習月カウント.学習年月をグループ化して降順で取得します
     * @summary 学習年月一覧
     * @param {CourseId} courseId コースID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudyMonthlyCountApi
     */
    public listStudyMonth(courseId: CourseId, options?: any) {
        return StudyMonthlyCountApiFp(this.configuration).listStudyMonth(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンの学習者情報を取得します（※体験ユーザーは使用しない）
         * @summary 学習者
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser: async (options: any = {}): Promise<RequestArgs> => {
            let localVarPath = `/user`;
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/user');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンの学習者情報を取得します（※体験ユーザーは使用しない）
         * @summary 学習者
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserResponse>> {
            const localVarAxiosArgs = await UserApiAxiosParamCreator(configuration).showUser(options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 認証/認可トークンの学習者情報を取得します（※体験ユーザーは使用しない）
         * @summary 学習者
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showUser(options?: any): AxiosPromise<UserResponse> {
            return UserApiFp(configuration).showUser(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 認証/認可トークンの学習者情報を取得します（※体験ユーザーは使用しない）
     * @summary 学習者
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public showUser(options?: any) {
        return UserApiFp(this.configuration).showUser(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserProgressApi - axios parameter creator
 * @export
 */
export const UserProgressApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンとコースIDに紐付く一本道コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・一本道コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneway: async (courseId: CourseId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling showOneway.');
            }
            let localVarPath = `/userProgress/{courseId}/oneway`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/userProgress/{courseId}/oneway');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証/認可トークンとコースIDに紐付く選択式コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・選択式コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSelection: async (courseId: CourseId, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'courseId' is not null or undefined
            if (courseId === null || courseId === undefined) {
                throw new RequiredError('courseId','Required parameter courseId was null or undefined when calling showSelection.');
            }
            let localVarPath = `/userProgress/{courseId}/selection`
                .replace(`{${"courseId"}}`, encodeURIComponent(String(courseId)));
            if (ApiUtility.getApiBasePath().indexOf('/sample') !== -1) localVarPath = ApiUtility.convertSampleUrl('GET', '/userProgress/{courseId}/selection');
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, 'https://example.com');
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarAppendArrayQueryParameter = {} as any;

            // authentication authToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
                    ? await configuration.apiKey("pUserToken")
                    : await configuration.apiKey;
                localVarHeaderParameter["pUserToken"] = localVarApiKeyValue;
            }


    
            const queryParameters = new URLSearchParams(localVarUrlObj.search);
            for (const key in localVarQueryParameter) {
                queryParameters.set(key, localVarQueryParameter[key]);
            }
            for (const key in options.query) {
                queryParameters.set(key, options.query[key]);
            }
            for (const key in localVarAppendArrayQueryParameter) {
                for (const valueIndex in localVarAppendArrayQueryParameter[key]) {
                    queryParameters.append(key, localVarAppendArrayQueryParameter[key][valueIndex]);
                }
            }
            localVarUrlObj.search = (new URLSearchParams(queryParameters)).toString();
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: localVarUrlObj.pathname + localVarUrlObj.search + localVarUrlObj.hash,
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserProgressApi - functional programming interface
 * @export
 */
export const UserProgressApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 認証/認可トークンとコースIDに紐付く一本道コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・一本道コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showOneway(courseId: CourseId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CourseProgress>> {
            const localVarAxiosArgs = await UserProgressApiAxiosParamCreator(configuration).showOneway(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         * 認証/認可トークンとコースIDに紐付く選択式コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・選択式コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showSelection(courseId: CourseId, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CourseProgress>>> {
            const localVarAxiosArgs = await UserProgressApiAxiosParamCreator(configuration).showSelection(courseId, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = {...localVarAxiosArgs.options, url: (configuration?.basePath || basePath) + localVarAxiosArgs.url};
                return axios.request(axiosRequestArgs);
            };
        },
    }
};

/**
 * UserProgressApi - factory interface
 * @export
 */
export const UserProgressApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 認証/認可トークンとコースIDに紐付く一本道コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・一本道コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showOneway(courseId: CourseId, options?: any): AxiosPromise<CourseProgress> {
            return UserProgressApiFp(configuration).showOneway(courseId, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証/認可トークンとコースIDに紐付く選択式コース進捗を取得します（※体験ユーザーは使用しない）
         * @summary 学習者進度・選択式コース進捗
         * @param {CourseId} courseId コースID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showSelection(courseId: CourseId, options?: any): AxiosPromise<Array<CourseProgress>> {
            return UserProgressApiFp(configuration).showSelection(courseId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserProgressApi - object-oriented interface
 * @export
 * @class UserProgressApi
 * @extends {BaseAPI}
 */
export class UserProgressApi extends BaseAPI {
    /**
     * 認証/認可トークンとコースIDに紐付く一本道コース進捗を取得します（※体験ユーザーは使用しない）
     * @summary 学習者進度・一本道コース進捗
     * @param {CourseId} courseId コースID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProgressApi
     */
    public showOneway(courseId: CourseId, options?: any) {
        return UserProgressApiFp(this.configuration).showOneway(courseId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証/認可トークンとコースIDに紐付く選択式コース進捗を取得します（※体験ユーザーは使用しない）
     * @summary 学習者進度・選択式コース進捗
     * @param {CourseId} courseId コースID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserProgressApi
     */
    public showSelection(courseId: CourseId, options?: any) {
        return UserProgressApiFp(this.configuration).showSelection(courseId, options).then((request) => request(this.axios, this.basePath));
    }
}


