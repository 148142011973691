import Vue from 'vue';
import moment from 'moment';


// 日時整形
Vue.filter('dtf', (value: string, format = 'YYYY/MM/DD HH:mm:ss'): string => {
  if (value === undefined || value === null || value === '') return '';
  return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
});

// 日付整形
Vue.filter('df', (value: string, format = 'YYYY/MM/DD'): string => {
  if (value === undefined || value === null || value === '') return '';
  return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format);
});

// 秒から"分:秒"へ変換
Vue.filter('msf', (value: number): string => {
  if (value === undefined || value === null) return '';
  const min = Math.floor(value / 60);
  const sec = value % 60;
  return ((min < 10) ? '0' : '') + min + ':' + ((sec < 10) ? '0' : '') + sec;
});

// 数字にカンマを足す
Vue.filter('nf', (value: string | number) => {
  if (value === undefined || value === null) return '';
  return Number(value).toLocaleString();
});

// 任意の小数点以下まで表示

const toFixed = (digit: number, value?: number | string | null): string => {
  if (value === undefined || value === null) return '';
  return (typeof value == 'string')? value : value.toFixed(digit);
};

Vue.filter('toFixed1', (value?: number | string | null): string => {
  return toFixed(1, value);
});

Vue.filter('toFixed2', (value?: number | string | null): string => {
  return toFixed(2, value);
});

// 配列表示
Vue.filter('joinArray', (array: Array<string>, separator = ','): string => {
  if (!Array.isArray(array)) return array;
  return array.join(separator);
});
