import { Component } from 'vue';
import { RouteConfig } from 'vue-router';
import { RoutePropsFunction } from 'vue-router/types/router';
import _ from 'lodash';

import Login from '@/user/pages/login/Login.vue';
import Unauthorized from '@/user/pages/unauthorized/Unauthorized.vue';
import Exception from '@/user/pages/exception/Exception.vue';

import Authorized from '@/user/pages/authorized/Authorized.vue';
import CourseSelect from '@/user/pages/authorized/courseSelect/CourseSelect.vue';
import SelectionUnitSelect from '@/user/pages/authorized/selectionUnitSelect/SelectionUnitSelect.vue';
import Study from '@/user/pages/authorized/study/Study.vue';
import Account from '@/user/pages/authorized/account/Account.vue';

import AuthorizedHistory from '@/user/pages/authorizedHistory/AuthorizedHistory.vue';
import History from '@/user/pages/authorizedHistory/history/History.vue';

import { USER_STORAGE_KEY } from '@/classes/common/Constants';
import StorageUtility from '@/classes/common/StorageUtility';




/** ルート型定義 */
export interface RouteData {
  path: string
  name: string
  component?: Component
  props?: boolean | Record<string, string> | RoutePropsFunction,
  redirect?: Function
}

/** 公開ルート型定義 */
interface PublicRoutesData {
  [key: string]: RouteData // index signature
  /** ログイン */
  Login: RouteData
  /** 認証エラー */
  Unauthorized: RouteData
  /** エラー */
  Exception: RouteData
  /** URLエラー */
  NotFound: RouteData
}

/** 認証後ルート型定義 */
interface AuthorizedRoutesData {
  [key: string]: RouteData // index signature
  /** 科目選択 */
  CourseSelect: RouteData
  /** ユニット選択（選択式） */
  SelectionUnitSelect: RouteData
  /** 学習画面 */
  Study: RouteData
  /** アカウント設定 */
  Account: RouteData
};

/** 認証後履歴機能ルート型定義 */
interface AuthorizedHistoryRoutesData {
  [key: string]: RouteData // index signature
  /** 学習履歴 */
  History: RouteData
};

/** 学習機能ルート型定義 */
export type RoutesType = PublicRoutesData & AuthorizedRoutesData & AuthorizedHistoryRoutesData;

/** 学習機能公開ルート定数 */
export const PublicRoutes: PublicRoutesData = {
  LoginRedirect: {
    path: '/user',
    name: 'LoginRedirect',
    redirect: () => {
      const orgId  = StorageUtility.getLocalData<string>(USER_STORAGE_KEY.ORG_ID);
      const roomId = StorageUtility.getLocalData<string>(USER_STORAGE_KEY.ROOM_ID);
      if (orgId === null || roomId === null) return 'NotFound';
      return `/user/login/${orgId}/${roomId}`;
    },
  },
  Login: {
    path: '/user/login/:orgId/:roomId',
    name: 'Login',
    component: Login,
  },
  Unauthorized: {
    path: '/user/unauthorized',
    name: 'Unauthorized',
    component: Unauthorized,
  },
  Exception: {
    path: '/user/exception',
    name: 'Exception',
    component: Exception,
  },
  NotFound: {
    path: '*',
    name: 'NotFound',
    component: Exception,
  }
};

/** 学習機能認証後ルート定数 */
export const AuthorizedRoutes: AuthorizedRoutesData = {
  CourseSelect: {
    path: '/user/courseSelect',
    name: 'CourseSelect',
    component: CourseSelect,
  },
  SelectionUnitSelect: {
    path: '/user/selection/unitSelect/:courseId/:courseName',
    name: 'SelectionUnitSelect',
    component: SelectionUnitSelect,
  },
  Study: {
    path: '/user/study/:courseId/:courseName', // query->sectionNo,unitNo
    name: 'Study',
    component: Study,
  },
  Account: {
    path: '/user/account',
    name: 'Account',
    component: Account,
  }
};

/** 認証後管理機能ルート定数 */
export const AuthorizedHistoryRoutes: AuthorizedHistoryRoutesData = {
  History: {
    path: '/user/history/:courseId/:courseName', // query->ym
    name: 'History',
    component: History,
  }
};

/**
 * route設定管理クラス
 */
export default class AppRouteConfig {

  /**
   * vue-routerの設定用配列を取得します。
   *
   * @returns vue-routerの設定用配列
   */
  public static getVueRouterConfig(): RouteConfig[] {
    const getConfigs = (routes: PublicRoutesData | AuthorizedRoutesData | AuthorizedHistoryRoutesData): RouteConfig[] => {
      return _.map(
        routes,
        (route) => {
          return _.pick(route, ['path', 'component', 'name', 'redirect']) as RouteConfig;
        }
      );
    };

    const configs = getConfigs(_.merge(PublicRoutes));
    configs.push(
      {
        path: '/user/authorized',
        component: Authorized,
        children: getConfigs(AuthorizedRoutes)
      }
    );
    configs.push(
      {
        path: '/admin/authorizedHistory',
        component: AuthorizedHistory,
        children: getConfigs(AuthorizedHistoryRoutes)
      }
    );

    return configs;
  }



}
