import Vue from 'vue';
import moment from 'moment';

import { UserResponse } from '@/openapi/user/index';
import { UserType } from '@/openapi/enum';

import UserApiUtility from '@/classes/common/UserApiUtility';
import { PublicRoutes } from '@/user/router/AppRouteConfig';




type State = {
  user: UserResponse | null;
};

const state = Vue.observable<State>({
  user: null
});


export default Vue.extend({

  name: 'UserMixin',

  computed: {
    user(): UserResponse | null {
      return state.user;
    },
    nickname(): string {
      return this.user !== null && this.user.nickname !== undefined ? this.user.nickname : '';
    },
    userPass(): string {
      return this.user !== null ? this.user.userPass : '';
    },
    isGuest(): boolean {
      return this.user !== null && this.user.userType === UserType.guest;
    },
    isAlwaysCorrect(): boolean {
      return this.user !== null && this.user.userType === UserType.correct;
    },
    isSectionComplete(): boolean {
      return this.user !== null && this.user.userType === UserType.complete;
    },
    isOneUnitLeft(): boolean {
      return this.user !== null && this.user.userType === UserType.oneUnitLeft;
    },
    isLicensed(): boolean {
      return this.user !== null && this.user.expirationDate !== undefined;
    },
    withinExpirationDate(): boolean {
      return this.user !== null && this.user.expirationDate !== undefined && Number(moment().format('YYYYMMDD')) <= this.user.expirationDate;
    },
    expirationDate(): string {
      return this.isLicensed ? moment(this.user!.expirationDate, 'YYYYMMDD').format('YYYY/MM/DD') : '';
    }
  },

  methods: {
    async loadUserState(isForce = false): Promise<void> {
      // tokenがある場合
      if (UserApiUtility.hasToken()) {
        // ユーザ情報がない、または強制更新の場合にはユーザ取得
        if (state.user === null || isForce) {
          const response = await UserApiUtility.getUserApi().showUser();
          if (response.data === null) throw new Error('no user info');
          state.user = response.data;
        }

      // tokenがない場合
      } else {
        if (state.user !== null) return; // ユーザ情報がある場合はreturn（体験ユーザ）

        // 認証エラーへ遷移
        this.$router.push(PublicRoutes.Unauthorized);
      }
    },
    removeUserState(): void {
      state.user = null;
    },
    setGuestUserState(): void {
      state.user = {
        nickname: '体験ユーザー',
        userType: UserType.guest,
        userPass: ''
      };
    },
    logout(): void {
      UserApiUtility.removeToken();
      this.removeUserState();
    }
  }

});
