import Vue from 'vue';



export default Vue.extend({

  name: 'ConfirmDialog',

  props: {
    visible: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: ''
    }
  },

  methods: {
    confirm(): void {
      this.$emit('onConfirm');
    },
    cancel(): void {
      this.$emit('onCancel');
    }
  }



});
