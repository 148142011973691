import mixins from 'vue-typed-mixins';

import { CourseId } from '@/openapi/enum';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyMixin, { STUDY_TYPE } from '@/user/mixins/StudyMixin';
import WindowAdjustMixin from '@/user/mixins/WindowAdjustMixin';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';




export default mixins(RoutesMixin, StudyMixin, WindowAdjustMixin).extend({

  name: 'SelectionUnitSelect',

  components: {
    Header,
    BackButton,
    LogoutButton,
  },

  data(): {
    courseId: CourseId;
  } {
    return {
      courseId: this.$route.params.courseId as CourseId
    };
  },

  computed: {
    title(): string {
      return `${this.$route.params.courseName}メニュー`;
    },
    bgStyle(): { 'background-image'?: string } {
      // 完了した単元数をカウント
      let section = 0;
      for (const p of this.progresses) {
        if (p.unitNos.length < this.unitCountPerSection) break;
        section++;
      }
      // マスタ作成時にセクション数を超えることがあるので上限を超えないよう考慮
      if (this.sectionCount < section) section = this.sectionCount;

      return section > 0
        ? { 'background-image': `url(${require(`@/assets/img/unitSelect/${this.courseId}_${this.isPortrait ? 'v' : 'h'}_${section}.svg`)})` }
        : {};
    },
  },

  created(): void {
    this.loadProgressState(this.courseId);
  },

  methods: {
    back(): void {
      this.$router.push(this.routes.CourseSelect);
    },
    startStudy(): void {
      // 未成功の最初のユニットの学習画面へ
      for (let i = 0; i < this.sectionCount; i++) {
        for (let j = 0; j < this.unitCountPerSection; j++) {
          if (!this.checkSuccessUnit(i + 1, j + 1)) {
            this.toStudy(i + 1, j + 1, STUDY_TYPE.NORMAL);
            return;
          }
        }
      }
    },
    selectSectionUnit(sectionNo: number, unitNo: number): void {
      const studyType = this.checkSuccessUnit(sectionNo, unitNo) ? STUDY_TYPE.REVIEW : STUDY_TYPE.NORMAL;
      this.toStudy(sectionNo, unitNo, studyType);
    },
    toStudy(sectionNo: number, unitNo: number, type: number): void {
      this.$router.push({
        name: this.routes.Study.name,
        params: this.$route.params,
        query: {
          sectionNo: String(sectionNo),
          unitNo: String(unitNo),
          studyType: String(type)
        }
      });
    },
    toHistory(): void {
      const link = this.$router.resolve({name: this.routes.History.name, params: this.$route.params});
      window.open(link.href, '_blank');
    }
  }



});
