// 定数定義集

export const PUBLIC_PATH = process.env.VUE_APP_PUBLIC_PATH;

export const DEFAULT_PAGE_SIZE = 10;

export const MOMENT_DEFAULT_FORMAT = 'YYYY/MM/DD HH:mm:ss.SSS';

export const ADMIN_STORAGE_KEY = {
  ORG_ID: 'P_ADMIN_ORG_ID',
  RESOURCE_NAME: 'P_ADMIN_RESOURCE_NAME',
  FIRST_VIEWED_MANABI_OVERVIEW: 'P_ADMIN_FIRST_VIEWED_MANABI_OVERVIEW',
};

export const USER_STORAGE_KEY = {
  ORG_ID: 'P_USER_ORG_CODE',
  ROOM_ID: 'P_USER_ROOM_CODE',
  RESOURCE_NAME: 'P_USER_RESOURCE_NAME',
  UNIT_PAGE: 'P_USER_UNIT_PAGE',
};

export const FLAG = {
  OFF: '0',
  ON: '1',
};

export const ORG = {
  ID: {
    MANABI: 'manabi',
    GOOGLE: 'google',
  },
  RESOURCE_NAME: {
    DEFAULT: 'default',
    MANABI: 'manabi',
    GOOGLE: 'google',
  }
};

export const ROOM = {
  ID: {
    DEMO: 'demo',
  }
};
