/**
 * WebStorage関連のutility
 */
export default class StorageUtility {




  /**
   * 外部呼出し不可
   */
  private constructor() {
    // 外部呼出し不可
  }




  /**
   * 指定キーで指定データクラスをlocalStorageに保存します。
   *
   * @param key
   * @param data
   */
  public static setLocalData<T>(key: string, data: T): void {
    StorageUtility.setData(localStorage, key, data);
  }

  /**
   * 指定キーでlocalStorageからデータクラスを取得します
   *
   * @param key
   * @return データクラスインスタンス
   */
  public static getLocalData<T>(key: string): T {
    return StorageUtility.getData(localStorage, key);
  }

  /**
   * 指定キーで指定データクラスをsessionStorageに保存します。
   *
   * @param key
   * @param data
   */
  public static setSessionData<T>(key: string, data: T): void {
    StorageUtility.setData(sessionStorage, key, data);
  }

  /**
   * 指定キーでsessionStorageからデータクラスを取得します
   *
   * @param key
   * @return データクラスインスタンス
   */
  public static getSessionData<T>(key: string): T {
    return StorageUtility.getData(sessionStorage, key);
  }




  /**
   * 指定キーで指定データクラスをWebStorageに保存します。
   *
   * @param storage
   * @param key
   * @param data
   */
  private static setData<T>(storage: Storage, key: string, data: T): void {
    storage.setItem(key, JSON.stringify(data));
  }

  /**
   * 指定キーでWebStorageからデータクラスを取得します
   *
   * @param storage
   * @param key
   * @return データクラスインスタンス
   */
  private static getData<T>(storage: Storage, key: string): T {
    const saved = storage.getItem(key);
    const data = saved !== null ? JSON.parse(saved) : null;
    return data as T;
  }

}
