import Vue from 'vue';

import { USER_STORAGE_KEY, ORG, ROOM } from '@/classes/common/Constants';
import StorageUtility from '@/classes/common/StorageUtility';
import UserApiUtility from '@/classes/common/UserApiUtility';


type State = {
  orgId: string | null;
  roomId: string | null;
  resourceName: string | null;
};

const state = Vue.observable<State>({
  orgId: null,
  roomId: null,
  resourceName: null,
});


export default Vue.extend({

  name: 'OrgRoomMixin',

  computed: {
    orgId(): string {
      return state.orgId === null ? '' : state.orgId;
    },
    roomId(): string {
      return state.roomId === null ? '' : state.roomId;
    },
    resourceName(): string {
      return state.resourceName === null ? '' : state.resourceName;
    },
    isManabi(): boolean {
      return this.resourceName === ORG.RESOURCE_NAME.MANABI;
    },
    isDefault(): boolean {
      return this.resourceName === ORG.RESOURCE_NAME.DEFAULT;
    },
    isGoogle(): boolean {
      return this.resourceName === ORG.RESOURCE_NAME.GOOGLE;
    },
    isDemoRoom(): boolean {
      return this.roomId === ROOM.ID.DEMO;
    },
    isOrgGoogle(): boolean {
      return this.orgId === ORG.ID.GOOGLE;
    }
  },

  methods: {
    async setOrgRoomState(orgId: string, roomId: string): Promise<void> {
      state.orgId = orgId;
      state.roomId = roomId;

      // css等を出しわけるため、組織Idに紐づく組織を取得し、リソース名を設定
      const response = await UserApiUtility.getOrgApi().showOrg(orgId);
      state.resourceName = response.data.resourceName;

      StorageUtility.setLocalData<string>(USER_STORAGE_KEY.ORG_ID, orgId);
      StorageUtility.setLocalData<string>(USER_STORAGE_KEY.ROOM_ID, roomId);
      StorageUtility.setLocalData<string>(USER_STORAGE_KEY.RESOURCE_NAME, state.resourceName);
    },
    // ログイン画面以外でリロードした時に呼び出される
    loadOrgRoomState(): void {
      state.orgId = StorageUtility.getLocalData<string>(USER_STORAGE_KEY.ORG_ID);
      state.roomId = StorageUtility.getLocalData<string>(USER_STORAGE_KEY.ROOM_ID);
      state.resourceName = StorageUtility.getLocalData<string>(USER_STORAGE_KEY.RESOURCE_NAME);
    }
  }

});
