
import mixins from 'vue-typed-mixins';

import UserMixin from '@/user/mixins/UserMixin';



export default mixins(UserMixin).extend({

  name: 'Authorized',

  created(): void {
    this.loadUserState();
  },

});
