import Vue from 'vue';
import axios, { AxiosInstance } from 'axios';
import { Loading } from 'element-ui';
import { ElLoadingComponent } from 'element-ui/types/loading';
import Cookies from 'js-cookie';

import { Configuration } from '@/openapi/common';
import { BaseAPI } from '@/openapi/common/base';

import StorageUtility from '@/classes/common/StorageUtility';




export default class ApiUtility {

  /** APIの基本パス（継承先でこれを変更すれば他のAPIにアクセスできる想定） */
  protected static readonly apiBasePath: string = '';

  /** 認証/認可トークンキー */
  protected static readonly tokenKey: string = 'token';

  /** クッキートークンのパス（空文字以外なら設定する） */
  protected static readonly cookieTokenPath: string = '';

  /** 400エラーを正常系として処理するか否か */
  protected static readonly isBadRequestResolved: boolean = true;

  /** トークン保存をローカルストレージにするか否か、デフォルトはセッションストレージ */
  protected static readonly isPersistentToken: boolean = false;

  /** element-ui loading */
  private static loadingInstance: ElLoadingComponent | null = null;

  /** 通信中リクエスト数 */
  private static connectionCount = 0;




  /**
   * APIの基本パスを取得します
   *
   * @returns
   */
  public static getApiBasePath(): string {
    return this.apiBasePath;
  }

  /**
   * openapi-generator生成クラスを取得します
   *
   * @param type
   * @param withAuthorization
   * @returns
   */
  public static getApi<T extends BaseAPI>(
    type: new (
      configuration?: Configuration,
      basePath?: string,
      axios?: AxiosInstance,
    ) => T,
    withAuthorization = true,
  ): T {
    return new type(
      undefined,
      this.apiBasePath,
      this.getInstance(withAuthorization),
    );
  }

  /**
   * ストレージにトークンが存在するか確認します
   *
   * @return 存在有無
   */
  public static hasToken(): boolean {
    return this.getToken() !== null;
  }

  /**
   * ストレージからトークンを取得します
   *
   * @return token
   */
  public static getToken(): string {
    return this.isPersistentToken ? StorageUtility.getLocalData<string>(this.tokenKey) : StorageUtility.getSessionData<string>(this.tokenKey);
  }

  /**
   * ストレージと必要ならcookieからトークンを削除します
   */
  public static removeToken(): void {
    this.isPersistentToken ? localStorage.removeItem(this.tokenKey) : sessionStorage.removeItem(this.tokenKey);
    if (this.cookieTokenPath.length > 0) Cookies.remove(this.tokenKey, { path: this.cookieTokenPath });
  }


  /**
   * APIのURLをサンプルjsonを参照するためのURLに変換します
   *
   * @param httpMethod
   * @param originalUrl
   */
  public static convertSampleUrl(httpMethod: string, originalPath: string): string {
    const paths = originalPath.split('/')
      .map(p => {
        if (p.indexOf('{') === -1) return p;
        return 'p';
      });
    return `${paths.join('/')}/${httpMethod.toLowerCase()}.json`;
  }

  /**
   * 通信用axiosインスタンスを取得します
   *
   * @param withAuthorization
   * @param withLoading
   * @return AxiosInstance
   */
  protected static getInstance(withAuthorization = true, withLoading = true): AxiosInstance {
    const closeLoading = async (): Promise<void> => {
      if (this.connectionCount === 0) {
        await Vue.nextTick();
        if (this.loadingInstance !== null) this.loadingInstance.close();
        this.loadingInstance = null;
      }
    };

    // axios custom instance
    const instance = axios.create({
      timeout: 60000,
      maxRedirects: 0,
      withCredentials: true
    });

    instance.interceptors.request.use(
      (requestConfig) => {
        this.connectionCount++;
        if (withAuthorization) {
          requestConfig.headers.common[this.tokenKey] = this.getToken();
        }
        if (withLoading) {
          this.loadingInstance = Loading.service({
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
        }
        return requestConfig;
      }
    );

    instance.interceptors.response.use(
      (response) => {
        this.connectionCount--;
        closeLoading();
        const token = response.headers[this.tokenKey];
        if (token !== undefined) this.saveToken(token);
        return response;
      },
      (error) => {
        this.connectionCount--;
        const responseCode = error.response === undefined ? 0 : error.response.status;
        closeLoading();
        switch (responseCode) {
          // 入力エラーは正常系として処理
          case 400:
            if (this.isBadRequestResolved) return Promise.resolve(error.response);
            break;
            // ログインなど
          case 401:
            if (!withAuthorization) return Promise.resolve(error.response);
            break;
        }

        // unhandledrejection でイベント補足の想定
        return Promise.reject(error);
      },
    );
    return instance;
  }

  /**
   * ストレージと必要ならcookieにトークンを保存します
   *
   * @param token
   */
  private static saveToken(token: string): void {
    this.isPersistentToken ? StorageUtility.setLocalData<string>(this.tokenKey, token) : StorageUtility.setSessionData<string>(this.tokenKey, token);
    if (this.cookieTokenPath.length > 0) Cookies.set(this.tokenKey, token, { path: this.cookieTokenPath });
  }




}
