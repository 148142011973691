import mixins from 'vue-typed-mixins';

import Header from '@/user/components/header/Header.vue';
import BackButton from '@/user/components/header/backButton/BackButton.vue';
import LogoutButton from '@/user/components/header/logoutButton/LogoutButton.vue';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import UserMixin from '@/user/mixins/UserMixin';

export default mixins(RoutesMixin, UserMixin).extend({

  name: 'Unauthorized',

  components: {
    Header,
    BackButton,
    LogoutButton,
  },

  computed: {
    errorMessage(): string {
      const message = this.$route.query.message;
      if (message === undefined || message === 'No message available') return '認証エラーが発生しました';
      return message as string;
    }
  },

  created(): void {
    this.logout();
  },

});
