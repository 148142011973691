import mixins from 'vue-typed-mixins';

import UserMixin from '@/user/mixins/UserMixin';


export default mixins(UserMixin).extend({

  name: 'Header',

  props: {
    hiddenNickname: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '',
    }
  },

  computed: {
    titleCssClass(): string {
      if      (this.title.length > 15) return 'headerTitleSS';
      else if (this.title.length > 11) return 'headerTitleS';
      else                             return 'headerTitle';
    }
  }



});
