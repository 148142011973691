import mixins from 'vue-typed-mixins';

import { CourseId, ResultNo } from '@/openapi/enum';

import RoutesMixin from '@/user/mixins/RoutesMixin';
import StudyLogMixin from '@/mixins/StudyLogMixin';

import UserApiUtility from '@/classes/common/UserApiUtility';
import { StudyLogListItemResponse } from '@/openapi/common';




export default mixins(RoutesMixin, StudyLogMixin).extend({
  name: 'History',

  data(): {
    courseId: CourseId;
    studyMonths: number[];
    studyLogs: StudyLogListItemResponse[];
    resultNos: object;
    displayMonth: number;
  } {
    return {
      courseId: this.$route.params.courseId as CourseId,
      studyMonths: [],
      studyLogs: [],
      resultNos: ResultNo,
      displayMonth: 0,
    };
  },

  computed: {
    dispHistoryPrevBtn(): boolean {
      return this.displayMonth !== this.studyMonths[this.studyMonths.length - 1];
    },
    dispHistoryNextBtn(): boolean {
      return this.displayMonth !== this.studyMonths[0];
    },
  },

  async created(): Promise<void> {
    const response = await UserApiUtility.getStudyMonthlyCountApi().listStudyMonth(this.courseId);
    this.studyMonths = response.data;

    if (this.studyMonths.length > 0) this.loadStudyLogs(this.studyMonths[0]);
  },

  methods: {
    async loadStudyLogs(studyMonth: number): Promise<void> {
      const response = await UserApiUtility.getStudyLogApi().listStudyLog(this.courseId, studyMonth);
      this.studyLogs = response.data;
      this.displayMonth = studyMonth;
    },
    history(feed: number): void {
      const targetIndex = this.studyMonths.indexOf(this.displayMonth) + feed;
      if (targetIndex < 0 || this.studyMonths.length < targetIndex + 1) return;
      this.loadStudyLogs(this.studyMonths[targetIndex]);
    },
  },

});
